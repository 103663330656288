/* Roboto Font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* Nexa Font */
@font-face {
    font-family: "Nexa-bold";
    src: url("../Nexa-Font/bold.otf") format("opentype");
}

/* Futura Md BT Font */
@font-face {
    font-family: "Futura Md BT";
    src: url("../futura-md-bt/futurab.ttf") format("TrueType");
}

/* Bebas Font */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

/* Outfit Font */
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');;

:root {
    --primaryColor: #FBB03B;
    --blackColor: #000;
    --whiteColor: #fff;
    --baseBorderRadius: 0px;
    --grayColor: #525252;
    --lightColor: #E0E0E0;
    --bebas-family: "Bebas Neue", sans-serif;
    --roboto-family: "Roboto", sans-serif;
}

#root{
    background-color: #000 !important;
}

#root{
    background-color: #000 !important;
}

/*/----- login page -----/*/
.auth-section-wrapper{
    background-color: #000;
    padding-top: 80px;
    padding-bottom: 80px;
    height: 100vh;
    overflow-y: scroll;
}

.site-logo{
    width: 70%;
}

.site-logo img{
    width: 100%;
}

.single-input-wrapper label{
    font-size: 14px;
    font-weight: 900;
    line-height: 21px;
    text-align: left;   
    color: #525252; 
}

.single-input-wrapper input{
    width: 432px;
    height: 50px;
    padding: 13px 10px 13px 10px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 0px;
    background-color: rgba(20, 20, 20, 1) !important;
    box-shadow: none !important;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff !important;
}

.single-input-wrapper input::placeholder{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
}

.app-logo-wrapper{
    text-align: center;
    margin-bottom: 10px;
}

.app-logo-wrapper h2{
    font-family: "Bebas Neue", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.login-btn-wrapper button{
    width: 100%;
    height: 50px;
    border-radius: 0px;
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 24px;
    font-family: "Roboto", sans-serif !important;
    background-color: #FBB03B !important;
    color: #000 !important;
}

.auth-footer-wrapper{
    text-align: center;
    padding-top: 20px;
}

.social-login p{
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: #525252;
}

/*/----- signup page -----/*/
.dob-input-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.dob-input-content{
    width: 100%;
}

.dob-input-content label{
    font-weight: 400 !important;
}

.dob-input-wrapper select{
    height: 50px;
    padding: 13px 10px 13px 10px;
    border: 1px solid;
    border-color: #fff;
    border-radius: 0px;
    background-color: rgba(20, 20, 20, 1);
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
}

.signup-next-btn-wrapper{
    text-align: right;
}

.signup-next-btn{
    font-size: 18px !important;
    font-weight: 900 !important;
    line-height: 27px !important;
    height: 50px;
    Padding: 12px 20px 11px 20px !important;
    color: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 0px !important;
    margin-top: 30px;
}

.signup-next-btn.dob-selector{
    font-weight: 500 !important;
}

.signup-next-btn.dob-selector:hover{
    color: #000 !important;
    border: 1px solid #000 !important;
    background-color: #fff !important;
}

/*/----- signup step2 -----/*/
.language-input-wrapper label{
    display: inline !important; 
    font-weight: 400;
}

.language-input-wrapper input{
    width: 14px;
    height: 14px;
    padding: 0;
    border: 1.5px solid;
}

.language-input-wrapper .form-check-input:checked{
    border-color: #fff;
    background-size: 18px;
}

.other-industry-wrapper input{
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-width: 2px;
}

.other-industry-wrapper input::placeholder{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #525252;
}

/*/---- confirmation step ----/*/
.confirmation-msg-wraper{
    text-align: center;
}

.confirmation-msg-wraper h2{
    font-size: 32px;
    font-weight: 900;
    line-height: 48px;
    color: #fff;
}

.confirmation-msg-wraper p{
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #fff;
}

.h-100-vh{
    height: 100vh;
}


/*/----- multi Step Progress Bar -----/*/
.progressBar-wrapper{
    margin-top: 45px;
    margin-bottom: 80px;
    padding: 0 55px;
}

.progressBar-wrapper .RSPBprogressBar{
    height: 1px;
}

.step-initializer{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6B6B6B;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Nexa-bold';
    background-color: #1B1B1B;
}

.step{
    width: 50px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
}

.step p{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #525252;
    margin-bottom: 0;
    position: absolute;
    bottom: -28px;
    width: 200px;
}

.step.completed .step-initializer{
    background-color: #9D9D9D;
    color: #000;
}

.step.completed p{
    font-weight: 900;
    color: #FDFDFD;
}

.register-link-wrapper{
    /* display: flex; */
    justify-content: center;
    /* width: 100%; */
}

.register-link-wrapper p{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.register-link-wrapper p a{
    color: #FBB03B;
    text-decoration: none;
}

/* @keyframes customAnimation {
    0% { transform: scale(0.9); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
} */


/*/--- slider animation ---/*/
@keyframes customAnimation {
    0% { 
        transform: translateX(-100%);
        opacity: 0; 
    }
    100% { 
        transform: translateX(0);
        opacity: 1; 
    }
}

/* Apply the animation to the heading */
/* .animate-heading {
    animation: customAnimation 0.5s ease-in-out;
    animation-fill-mode: backwards; 
} */

/* Delay each text element */
/* .animate-heading:nth-child(1) {
    animation-delay: 0.5s;
}

.animate-heading:nth-child(2) {
    animation-delay: 1.5s; 
}

.animate-heading:nth-child(3) {
    animation-delay: 2.5s; 
}

.animate-heading:nth-child(4) {
    animation-delay: 3.5s;
}

.animate-heading:nth-child(5) {
    animation-delay: 4s; 
} */

.typing-demo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    animation: typing 2s steps(40) forwards;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid transparent;
}
  
.typing-demo:nth-child(1) {
  animation-delay: 1s;
  top: 25%;
}
  
.typing-demo:nth-child(2) {
  animation-delay: 3s;
  top: 40%;
}
   
.typing-demo:nth-child(3) {
  animation-delay: 5s;
  top: 55%;
}
  
.typing-demo:nth-child(4) {
  animation-delay: 4s;
  top: 55%;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 45ch;
  }
}
      
@keyframes blink {
  50% {
    border-color: black;
  }
}

.typing-demo2 {
    width: 0;
    animation: typing2 2s steps(30) forwards;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid transparent;
    margin-left: auto;
    margin-right: auto;
}

@keyframes typing2 {
    from {
      width: 0;
    }
    to {
      width: 32ch;
    }
  }
/*/---- slider animation ----/*/

.banner-signup-btn{
    transition: all 0.5s ease-in !important;
    position: relative;
    animation: customAnimation 0.8s ease-in-out;
    animation-fill-mode: backwards; 
    animation-delay: 5s;
    width: 205px !important;
}

.banner-explore-btn{
    animation: customAnimation 0.8s ease-in-out;
    animation-fill-mode: backwards; 
    animation-delay: 5s;
}

.signUp-avatar{
    opacity: 0;
    position: absolute;
    margin-right: 5px;
    left: 0;
    top: 14px;
    transition: all 0.5s ease-in;
}

.banner-signup-btn .btn-content{
    position: absolute;
    width: max-content;
    top: 12px;
    left: 50%;
    transform: translateX(-50%);
    transition: left 0.5s ease-in;
    text-transform: uppercase;
}

.banner-signup-btn:hover{
    background-color: #FBB03B !important;
    color: #fff !important;
    align-self: center;
}

.banner-signup-btn:hover .signUp-avatar{
    opacity: 1;
    left: 20%;
}

.banner-signup-btn:hover .btn-content{
    left: 60%;
}

.banner-signup-btn.tanslated-btn:hover .btn-content{
    left: 55%;
}

.banner-signup-btn.tanslated-btn:hover .signUp-avatar{
    left: 13%;
}

.banner-signup-btn.tanslated-btn{
    width: 270px !important;
}

@media(min-width: 768px){
    .auth-section-wrapper.forgot-password-wrapper{
        height: 100vh;
    }
}

.single-check-input-wrapper .form-check{
    display: flex;
    align-items: center;
}

.single-check-input-wrapper .form-check-input{
    height: 25px;
    width: 25px;
    border-radius: 0px !important;
    background-color: transparent !important;
    border: 1px solid #fff !important;
    box-shadow: none !important;
    margin-top: 0px;
    margin-right: 10px;
}

.single-check-input-wrapper .form-check label{
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
}

.single-check-input-wrapper .form-check label a{
    color: #FBB03B;
    text-decoration: none;
}

#rc-anchor-container{
    background: #fff !important;
}

.rc-anchor-dark.rc-anchor-normal, .rc-anchor-dark.rc-anchor-compact{
    border: 1px solid #FBB03B !important;
}