.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
  }

  .footer-wrapper .spinner-container{
    height: 100%;
  }
  .footer-wrapper .spinner-container .spinner {
    width: 25px;
    height: 25px;
    border-left-color: #000;
  }
  
  .spinner {
    border: 5px solid rgba(0, 0, 0, 0.1);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-left-color: #FBB03B;
    animation: spin 1s infinite linear;
  }

  .loader-wrapper{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(23, 22, 22, 0.5);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  