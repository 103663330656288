@media(min-width: 1400px){
    .profile-content-cards{
        gap: 35px;
        justify-content: center;
    }
}

@media(min-width: 992px) and (max-width: 1200px){
    .share-btn{
        width: 250px !important;
    }
    .home-banner-wrapper h1,  .home-banner-wrapper .text-heading {
        font-size: 70px;
        line-height: 84px;
    }

    .profile-edit-controls .primary_outline_btn, 
    .profile-edit-controls .secondary_outline_btn{
        font-size: 13px !important;
        padding-left: 0.55rem;
        padding-right: 0.55rem;
    }
}

@media(max-width: 992px){
    .casting-call-tag{
        height: 28px;
        padding-top: 6px;
    }

    .step-two-next-btn{
        width: 100%;
    }

    .user-profile-wrapper {
        padding-top: 80px;
    }

    .notification-dropdown{
        left: -95px !important;
        /* transform: translateX(-50%); */
    }

    .profile-dropdown-wrapper{
        left: 50% !important;
        transform: translateX(-50%) !important;
        right: unset !important;
    }
}

@media(max-width: 768px){
    .site-logo{
        width: 100%;
        text-align: center;
        padding-bottom: 30px;
    }

    .chat-screen-content h2{
        font-size: 30px;
    }
}

@media(max-width: 600px){
    .step p{
        font-size: 13px;
        width: 110px;
    }
}

/*/--- navbar ---/*/
@media(max-width: 992px){
    .header-navbar .nav-link.active{
        padding: 0.5rem 10px;
        width: max-content;
    }

    .header-navbar .nav-item{
        margin-top: 10px;
    }

    .navbar-collapse{
        background: #000;
        padding-bottom: 30px;
    }

    .navbar-collapse form{
        margin-top: 20px;
    }
}

/*/--- new talent page ---/*/
@media(min-width: 1400px){
    .video-frame {
        position: relative;
        height: 235px;
    }
}

@media(max-width: 1200px){
    .featured-wrapper .video-frame{
        height: 530px;
    }

    .featured-wrapper .video-layer{
        height: 94%;
        width: 95%;
    }

    .video-layer {
        height: 72%;
    }
}

@media(max-width: 992px){
    .filter-wrapper{
        flex-wrap: wrap;
    }
    .filter-menu{
        width: 100%;
        margin-top: 20px;
    }
    .featured-wrapper .video-layer{
        height: 92%;
        width: 95%;
    }

    .featured-wrapper .video-frame{
        height: 400px;
    }

    .video-layer {
        height: 82%;
    }

    .home-banner-wrapper h1, .home-banner-wrapper .text-heading  {
        font-size: 50px;
        line-height: 50px;
    }    
}

@media(max-width: 767px){
 .video-frame {
        height: 300px;
    }

    .featured-wrapper .video-frame{
        height: 300px;
    }
}

@media(max-width: 768px){
    .active-filters{
        width: 100%;
    }

    .subscrition-modal-body-content h2{
        font-size: 65px;
        line-height: 55px;
    }

    .video-title, .video-title a{
        font-size: 20px;
        line-height: 22px;
    }
    
    .home-banner {
        padding-top: 60px;
    }

    .home-banner.main-banner {
        padding-top: 0px;
    }

    .featured-wrapper .video-layer {
        height: 89%;
        width: 93%;
    }

    .video-title{
        font-size: 20px;
    }

    .video-src{
        font-size: 14px;
    }

    .person-location span{
        font-size: 14px;
    }

    .person-contact a{
        font-size: 14px;
    }

    .home-banner-wrapper h1, .home-banner-wrapper .text-heading {
        font-size: 22px;
        line-height: 30px;
    }

    .home-banner-wrapper p{
        font-size: 18px;
    }

    .talent-wrapper h2, .featured-wrapper h2 {
        font-size: 50px;
        line-height: 60px;
    }

    .talent-wrapper h3, .featured-wrapper h3{
        font-size: 40px;
        line-height: 35px;
    }

    .talent-wrapper h4 {
        font-size: 26px;
    }

    .faqs-wrapper .accordion-button p {
        font-size: 14px;
    }

    .filter-menu .filter-link{
        margin-left: 0px;
        text-align: left;
        margin-top: 20px;
    }



}

@media(max-width: 520px){
    .featured-wrapper .video-layer {
        height: 88%;
    }

    .featured-wrapper .video-frame{
        height: 275px;
    }
}

@media(max-width: 500px){
    .video-frame {
        height: 262px;
    }
}

@media(max-width: 460px){
    .featured-wrapper .video-layer {
        height: 80%;
        /* top: 28px; */
    }

    .home-banner-wrapper p {
        font-size: 13px;
    }
}


@media(max-width: 430px){
    .featured-wrapper .video-layer {
        height: 75%;
        /* top: 34px; */
    }

    .video-frame{
        height: 226px;
    }
}


@media(max-width: 414px){
    .video-title{
        font-size: 13px;
        line-height: 22px;
    }

    .copy-right-wrapper p{
        line-height: 33px;
    }
}

@media(max-width: 400px){
    .featured-wrapper .video-layer {
        height: 88%;
        /* top: 14px; */
    }
    
    .featured-wrapper .video-frame {
        height: 210px;
    }

    .video-frame{
        height: 210px;
    }
}

@media(max-width: 360px){
    .event-wrapper .primary_btn, .event-wrapper .dark_btn {
        font-size: 16px !important;
    }

    .home-banner-wrapper h1, .home-banner-wrapper .text-heading  {
        font-size: 20px;
        line-height: 24px;
    }

    .featured-wrapper .video-layer {
        height: 85%;
    }
    
    .featured-wrapper .video-frame {
        height: 190px;
    }

    .video-frame {
        height: 195px;
    }
}

@media(max-width: 340px){
    .video-frame{
        height: 175px;
    }
}

/*/--- role description page ---/*/
@media(max-width: 500px){
    .role-sevices .role-sevice-link{
        font-size: 16px;
        margin-right: 10px;
    }
}

@media(max-width: 430px){
    .role-sevices{
        flex-wrap: wrap;
    }
    .role-sevices .role-sevice-link{
        margin-top: 10px;
    }
}

/*/--- film pitching booth page --- how it works section ---/*/
@media(max-width:992px){
    .process-step {
        font-size: 16px;
    }

    .home-banner.video-hub-banner{
        background-position: center !important;
    }
}

@media(max-width:768px){
    .banner-wrapper h2 {
        font-size: 36px;
        line-height: 40px;
    }

    .banner-wrapper p{
        font-size: 18px;
    }

    .section-heading,
    .profile-content h2{
        font-size: 44px;
        line-height: 40px;
    }

    .section-sub-heading{
        font-size: 32px;
        line-height: 40px;
    }

    .profile-content h4{
        font-size: 26px;
        line-height: 32px;
    }
}

@media(max-width:412px){
    .process-step {
        font-size: 13px;
    }
}

/*/--- user profile page ---/*/
@media(max-width: 768px){
    .user-video-caption {
        bottom: 23%;
    }

    .user-video-caption h3 {
        font-size: 16px;
        line-height: 22px;
    }

    .user-video-caption span{
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
    }

    .profile-clip .cld-video-player .vjs-big-play-button {
        top: 67%;
    }

    /* .user-bio-video-clip video,
    .user-profile-clip-wrap video{
        height: unset !important;
    } */
}

@media(max-width: 540px){
    .user-video-caption {
        left: 21%;
        width: 68%;
    }

    .edit-bio-video-clip {
        right: 20px;
        bottom: 20px;
    }

    .edit-profile-clip-caption .play-button img{
        width: 35px;
        height: 35px;
    }

    .edit-bio-video-clip svg{
        width: 30px !important;
        height: 30px !important;
    }
    .copy-right-wrapper p{
        line-height: 30px;
    }
}

@media(max-width: 480px){
    .user-video-caption {
        bottom: 21%;
    }
}

@media(max-width: 430px){
    .user-video-caption {
        bottom: 18%;
        left: 20%;
    }
}

/*/--- individual user profile page --- event section ---/*/
@media(max-width: 768px){
    .table-wrapper table td span{
        display: inline-block;
        width: 200px;
    }

    .user-calls-wrapper{
        padding: 0px;
    }

    .page-heading {
        font-size: 34px;
    }

    .avail-casting-call-header h3, .avail-casting-call-header h3 a {
        font-size: 22px;
    }

    .talent-wrapper h2, .casting-calls-wrapper h2{
        font-size: 50px;
    }

    .talent-wrapper h3, .casting-calls-wrapper h3{
        font-size: 30px;
        line-height: 22px;
    }
}

/*/--- individual user profile page ---/*/
@media(min-width: 1400px){
    .user-bio-video-clip .video-frame {
        height: 400px;
    }

    .user-profile-clip-wrap .video-frame {
        height: 500px;
    }
}

@media(max-width: 1200px){
    .user-profile-clip-wrap .video-frame {
        height: 355px;
    }
}

@media(max-width: 992px){
    .user-profile-clip-wrap .video-frame {
        height: 400px;
    }

    .user-bio-video-clip .video-frame {
        height: 386px;
    }

    .media-left-layer{
        width: 23%;
    }

    .media-right-layer{
        width: 23%;
    }
}

@media(max-width: 768px){
    .profile-content-cards{
        justify-content: center;
    }

    .media-left-layer{
        width: 47%;
    }

    .media-right-layer{
        width: 47%;
    }

  
}

@media(max-width: 767px){
    .user-bio-video-clip .video-frame {
        height: 300px;
    }

    .user-profile-clip-wrap .video-frame {
        height: 300px;
    }
}

@media(max-width: 575px){
    .user-profile-clip-wrap .video-frame {
        height: 315px;
    }
}

@media(max-width: 540px){
    .edit-about-wrapper button{
        width: 135px;
    }

    .user-profile-clip-wrap .video-frame {
        height: 280px;
    }

    .media-tabs .nav-tabs .nav-link{
        height: 40px;
        /* width: 120px; */
        font-size: 20px !important;
        padding-top: 0.025rem;
        padding-bottom: 0.025rem;
    } 

    .user-bio-video-clip .video-frame{
        height: 280px;
    }

    .role-descrition-content h3, .role-descrition-content h3 a{
        font-size: 25px;
        line-height: 30px;
    }

    .role-descrition-content h5{
        font-size: 16px;
    }
}

@media(max-width: 460px){
    .user-bio-video-clip .video-frame{
        height: 232px;
    }

    .user-profile-clip-wrap .video-frame {
        height: 250px;
    }
}

@media(max-width: 430px){
    .user-profile-clip-wrap .video-frame {
        height: 230px;
    }

    .user-bio-video-clip .video-frame{
        height: 220px;
    }

    /* .edit-profile-clip-caption .play-button {
        left: 10%;
    } */
}

@media (max-width: 390px) {

    .user-bio-video-clip .video-frame {
        height: 190px;
    }

    .user-profile-clip-wrap .video-frame {
        height: 215px;
    }
}

@media(max-width: 360px){
    .user-profile-clip-wrap .video-frame {
        height: 190px;
    }
    
    .media-img{
        height: 155px;
    }
}

/*/--- Casting calls creation page ---/*/

@media(max-width: 390px){
    .edit-calls-menu{
        top: 0;
    }

    .edit-calls-menu svg{
        width: 22px !important;
    }

    .edit-calls-menu-list{
        top: 25px;
    }
}

/* user film booth */

@media(min-width: 1400px){
    .user-film-booth-wrapper .video-frame {
        height: 195px;
    }
}

@media(max-width: 1200px) and (min-width: 992px){
    .user-film-booth-wrapper .video-frame {
        height: 138px;
    }
}

@media(max-width: 992px){
    .user-film-booth-wrapper .video-frame {
        height: 195px;
    }
}

@media(max-width: 767px){
    .user-film-booth-wrapper .video-frame {
        height: 295px;
    }
}

@media(max-width: 540px){
    .user-film-booth-wrapper .video-frame {
        height: 290px;
    }
}

@media(max-width: 500px){
    .user-film-booth-wrapper .video-frame {
        height: 265px;
    }
}

@media(max-width: 470px){
    .user-film-booth-wrapper .video-frame {
        height: 250px;
    }
}

@media(max-width: 435px){
    .user-film-booth-wrapper .video-frame {
        height: 230px;
    }
}

@media(max-width: 412px){
    .user-film-booth-wrapper .video-frame {
        height: 220px;
    }
}

@media(max-width: 390px){
    .user-film-booth-wrapper .video-frame {
        height: 208px;
    }
}

@media(max-width: 375px){
    .user-film-booth-wrapper .video-frame {
        height: 195px;
    }
}

@media(max-width: 350px){
    .user-film-booth-wrapper .video-frame {
        height: 175px;
    }
}

@media(max-width: 992px){
    .typing-demo:nth-child(1) {
        top: 30%;
    }
        
    .typing-demo:nth-child(2) {
      top: 38%;
    }
         
    .typing-demo:nth-child(3) {
      top: 46%;
    }
        
    .typing-demo:nth-child(4) {
      top: 46%;
    }
}

@media(max-width: 768px){
    .typing-demo:nth-child(1) {
        top: 35%;
    }
        
    .typing-demo:nth-child(2) {
      top: 40%;
    }
         
    .typing-demo:nth-child(3) {
      top: 45%;
    }
        
    .typing-demo:nth-child(4) {
      top: 45%;
    }
}

