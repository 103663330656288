:root {
    --primaryColor: #FBB03B;
    --secondaryColor: #000;
    --lightColor: #fff;
    --baseBorderRadius: 0px;
    --bebas-family: "Bebas Neue", sans-serif;
    --roboto-family: "Roboto", sans-serif;
}

*{
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
}

body.modal-open {
    overflow: auto !important;
}

body{
    color: #fff !important;
    background-color: #000;
    font-family: "Roboto", sans-serif !important;
    position: relative;
}

#root{
    background-color: #000 !important;
}

.h-100-p{
    height: 100%;
}

.h-100-v{
    /* height: 100vh; */
    /* overflow: auto; */
}

.h-100-v::-webkit-scrollbar{
    width: 0px;
}

/*/--- navbar ---/*/
.header-navbar{
    background: transparent;
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 1;
}

.navbar-toggler{
    background-color: #FBB03B !important;
    border: 1px solid #FBB03B !important;
}

.header-navbar .nav-link{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #fff;
    margin: 0px 10px;
}

.header-navbar .nav-link.active{
    font-weight: 700;
    color: #FBB03B !important;
    border: 1px solid #FBB03B;
}

.header-navbar .nav-link:hover,
.header-navbar .nav-link:focus{
    color: #FBB03B !important;
}

.profile-link:hover .nav_icons,
.search-link:hover .nav_icons{
    stroke: #FBB03B !important;
}

.profile-link{
    display: flex;
    align-items: center;
    position: relative;
}

.profile-dropdown-wrapper{
    position: absolute;
    top: 35px;
    right: 0;
    background-color: #000;
    box-shadow: 0 2px 12px 0 #2C2C2C;
    padding: 10px 10px 0px 10px;
    z-index: 99 !important;
}

.profile-dropdown-content ul{
    list-style: none;
    padding-left: 0px;
    width: 200px;
}

.profile-dropdown-item{
    border-bottom: 0.3px solid rgba(251, 176, 59, 0.85);
}

.profile-dropdown-item a{
    text-decoration: none;
    font-family: 'Roboto',sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
    width: 100%;
    display: block;
    padding: 10px 5px;
}

.profile-dropdown-item:hover,
.profile-dropdown-active{
    background-color: #FBB03B !important;
}

.profile-dropdown-item.auth-btns:hover{
    background-color: #000 !important;
}

.logout-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    background-color: #FBB03B !important;
    border: 1px solid #FBB03B;
    color: #fff !important;
    border-radius: 5px;
    font-family: 'Roboto',sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
}

/*/--- home page ---/*/
/*/ home page --- banner section /*/
.home-banner{
    height: 100vh;
    align-items: center;
    background-image: url('../images/banner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.home-page-wrapper{
    height: 100vh;
}

.banner-layer{
    background-color: rgba(0, 0, 0, 0.1);
    height: 100%;
}

.banner-layer2{
    height: 100%;
    background-color: rgba(0, 0, 0, 0.80);
}

.home-banner-wrapper h1,.home-banner-wrapper .text-heading {
    font-family: "Bebas Neue", sans-serif;
    font-size: 85px;
    font-weight: 400;
    line-height: 100px;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.home-banner-wrapper p{
    font-size: 25px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;   
    color: #858585; 
    margin-bottom: 0px;
}

.home-banner-wrapper button{
    text-transform: uppercase;
}

.explore-btn{
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: #FBB03B !important;
    background-color: transparent !important;
    border: 1px solid #FBB03B !important;
    border-radius: 0px !important;
    height: 50px;
    width: 200px;
    margin-top: 20rem;
}

/*/--- home page --- category section ---/*/
.category-wrapper{
    background-color: #000;
    padding-bottom: 20px;
    padding-top: 60px;
}

.category-link h2{
    font-family: "Bebas Neue", sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #FBB03B;
    margin-bottom: 0px;
}
.category-link a{
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #858585;
    width: 100%;
    border: 1px solid #858585;
    text-decoration: none;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    margin-top: 10px;
    transition: all 0.5s ease-in-out;
}

.category-link a:hover{
    font-family: "Bebas Neue", sans-serif;
    font-size: 22px;
    line-height: 26px;
    color: #000;
    background-color: #858585;
}

.category-link a:hover .link-arrow{
    fill: #000 !important;
}

/*/--- home page --- talent section ---/*/
.talent-wrapper{
    background-color: #000;
    padding-top: 60px;
}

.talent-wrapper h2,
.casting-calls-wrapper h2{
    font-family: "Bebas Neue", sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 98px;
    font-weight: 400;
    line-height: 80px;
    text-align: left;
    margin-bottom: 0px;
}

.talent-wrapper h3,
.casting-calls-wrapper h3{
    font-family: "Bebas Neue", sans-serif;
    color: #FBB03B;
    font-size: 58px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 0px;
}

.talent-wrapper h4{
    font-family: "Bebas Neue", sans-serif;
    color: #565656;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
}

.talent-frame-wrapper{
    margin-top: 20px;
}

.video-frame{
    position: relative;
    /* height: 200px; */
    height: 223px; /*figma height*/
}

.video-layer{
    position: absolute;
    top: 50%;
    left: 50%;
    height: 88%;
    width: 93%;
    background-color: rgba(65, 65, 65, 0.7);
    transform: translate(-50%, -50%);
}

.vjs-cloudinary-button{
    display: none !important;
}

.video-frame:hover .video-layer{
    display: none;
}

.video-title,
.video-title a{
    font-family: "Bebas Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 26px;
    text-align: left;
    color: #858585;
    text-decoration: none;
}

.video-src{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #fff;
}

.primary_btn{
    font-family: "Bebas Neue", sans-serif !important;
    font-size: 22px !important;
    font-weight: 400 !important;
    color: #000 !important;
    background-color: #FBB03B !important;
    border-color: #FBB03B !important;  
    border-radius: 0px !important;  
    width: 135px;
}

.primary_btn:hover{
    background-color: transparent !important;
    color: #FBB03B !important;
}

.filter-btn:hover .filter-path{
    fill: #FBB03B;
}

.secondary_outline_btn:hover{
    background-color: #fff !important;
    color: #000 !important;
}

.talent-wrapper button{
    height: 48px;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0;
}

/*/--- home page --- featured section ---/*/
.featured-wrapper{
    background-color: #000;
    padding: 30px 0px 60px 0px !important;
}

.featured-wrapper h2{
    font-family: "Bebas Neue", sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 98px;
    font-weight: 400;
    line-height: 100px;
    text-align: center;
    margin-bottom: 0px;
}

.featured-wrapper h3{
    font-family: "Bebas Neue", sans-serif;
    color: #FBB03B;
    font-size: 58px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 0px;
}

.featured-wrapper .video-frame{
    height: 500px;
}

.person-contact a{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #FBB03B;
    margin-left: 10px;
}

.person-location span{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;  
    color: #fff;  
    margin-left: 10px;
}

.featured-wrapper .video-layer{
    /* top: 16px; */
    height: 95%;
    width: 97%;
}

/*/--- home page --- footer section ---/*/
.footer-wrapper{
    background-color: #2C2C2C;
    padding-top: 30px;
    padding-bottom: 20px;
    border-top: 2px solid #FBB03B;
}

.footer-wrapper ul{
    list-style: none;
}

.footer-wrapper a{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 42px;
    text-transform: uppercase;
    color: #fff !important;
    text-decoration: none;
}

.footer-wrapper a{
    margin-right: 10px;
}

.footer-wrapper a:hover{
    color: #FBB03B !important;
}

.footer-wrapper h4{
    font-size: 20px;
    line-height: 35px;
    font-weight: 500;
}

.footer-logo{
    width: 130px;
    display: block;
}

.footer-logo img{
    width: 100%;
}

.subscription-method{
    width: 100%;
}

.subscription-method input{
    border: 1px solid #fff;
    border-radius: 0px;
    background-color: transparent;
    height: 50px;
    padding-left: 10px;
    color: #fff;
    width: 70%;
    font-size: 14px;
    font-weight: 400;
}

.subscription-method input::placeholder{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
}

.subscription-method button{
    height: 50px;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-align: center;
    width: 26%;
    margin-left: 10px;
}

.subscription-method p{
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    margin-bottom: 0px;
    width: 100%;
    margin-top: 10px;
}

/*/--- copy right ---/*/
.copy-right-wrapper{
    background-color: rgba(30, 30, 30, 1);
    /* height: 67px; */
}

.copy-right-wrapper p{
    font-size: 12px;
    font-weight: 400;
    line-height: 63px;
    text-align: center;
    margin-bottom: 0px;
    text-transform: capitalize;
}

.copy-right-wrapper a{
    color: #FBB03B;
    text-decoration: none;
    margin-left: 10px;
}

.error-msg{
    color: red;
    margin-bottom: 0px;
    margin-top: 5px;
}

/*/--- home page --- faqs section ---/*/
.faqs-wrapper{
    background-color: #000;
    padding: 10px 0px 60px 0px;
}

.faqs-heading{
    font-family: "Bebas Neue", sans-serif;
    font-size: 38px;
    font-weight: 400;
    line-height: 45.6px;
    text-align: left;
    color: #FBB03B;
}

.faqs-wrapper .accordion,
.faqs-wrapper .accordion-item {
    background: #000 !important;
    background-color: #000 !important;
}

.accordion {
    --cui-accordion-bg: #000 !important;
    --cui-accordion-active-bg: #343434 !important;
}

.faqs-wrapper .accordion-body{
    background-color: #343434;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    text-align: left;
    color: #fff;
    position: relative;
}

.faqs-wrapper .accordion-body h5{
    font-size: 16px;
    font-weight: 700;
}

.faqs-wrapper .accordion-item:first-of-type,
.faqs-wrapper .accordion-item:last-of-type{
    border-radius: 0px !important;
}

.accordion-item:not(:first-of-type){
    border: 1px solid #fff !important;
}

.faqs-wrapper .accordion-button{
    align-items: center !important;
}

.faqs-wrapper .accordion-button::after{
    border: 1px solid #858585;
    border-radius: 50%;
    background-image: url('../images/accordination-bg.png');
    background-size: 12px;
    background-position: center;
}

.faqs-wrapper .accordion-button:not(.collapsed)::after{
    background-image: url('../images/accordination-bg.png') !important;
}

.faqs-wrapper .accordion-button:not(.collapsed){
    box-shadow: none !important;
}

.accordination-header-btn{
    opacity: 1;
    transition: all 0.4s ease-in-out;
    width: 96%;
}

/* .faqs-wrapper .accordion-button:not(.collapsed) .accordination-header-btn{
    opacity: 0;
} */

.faqs-wrapper .accordion-button:focus{
    box-shadow: none !important;
}

.accordion-item:first-of-type>.accordion-header .accordion-button{
    border-radius: 0px !important;
}

.faqs-wrapper .accordion-button span{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #858585;
    text-transform: capitalize;
    word-break: break-word;
}

.accordination-header-btn .audition-detail-wrap p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;
}

.audition-detail-wrap{
    word-break: break-word;
}

.faqs-wrapper .accordion-button.collapsed p{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* .accordination-header-btn p {
    overflow: visible;
    white-space: normal;
} */

.faq-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.own-call-tag{
    background-color: rgba(251, 176, 59, 1);
    font-family: 'Roboto';
    font-weight: 900;
    color: rgba(0, 0, 0, 1);
    text-align: center;
    padding: 7px 15px;
    border-radius: 50px;
    width: 140px;
    height: 40px;
    font-size: 16px;
    line-height: 27px;
}

.view-auditions-btn{
    display: inline-block;
    background-color: transparent !important;
    font-family: 'Roboto' !important;
    font-weight: 600 !important;
    color: #fff !important;
    text-align: center;
    padding: 6px 10px;
    border: 1.5px solid #fff !important;
    border-radius: 50px !important;
    width: 140px;
    height: 40px;
    font-size: 16px !important;
    line-height: 27px;
    text-decoration: none;
}

.faq-footer p{
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
}

.faq-footer span{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
}

.audition-btn{
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0px !important;
    height: 50px;
    width: 180px;
    font-size: 18px !important;
    font-weight: 900 !important;
    line-height: 27px !important;
    display: flex;
    align-items: center;
}

/*/--- casting calls page ---/*/

/*/--- casting calls --- casting calls section ---/*/
.casting-calls-wrapper{
    padding-top: 60px;
    padding-bottom: 60px;
}

.avail-casting-call-wrapper{
    background-color: rgba(20, 20, 20, 1);
    padding: 20px;
    height: 100%;
    position: relative;
    z-index: 0;
}

.disalbe-layer-wrapper{
    position: absolute;
    width: 50%;
    height: 16%;
    text-align: right;
    padding: 25px;
    top: 0;
    right: 0;
    z-index: 1;
}

.disalbe-layer-wrapper p{
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: rgba(235, 165, 56, 1);
}

.casting-call-tag{
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #FBB03B;
    color: #000;
    font-weight: 900;
    font-size: 13px;
    font-family: 'Roboto';
    line-height: 18px;
    padding: 8px 5px;
    width: 124px;
    height: 34px;
    border-radius: 50px;
    text-align: center;
}

.disabled-txt{
    color: rgba(73, 73, 73, 1) !important;
    border-color: rgba(73, 73, 73, 1) !important;
    pointer-events: none !important;
}

.avail-casting-call-body{
    margin-bottom: 180px;
}

.avail-casting-call-footer{
    position: absolute;
    width: 100%;
    bottom: 20px;
    left: 0;
    height: 180px;
    padding: 20px;
}

.user-own-call .avail-casting-call-body{
    margin-bottom: 120px;
}

.user-own-call .avail-casting-call-footer{
    height: 120px;
}

.casting-call-desc{
    /* overflow-y: auto; */
    /* height: 100px; */
    overflow: hidden;
    /* white-space: nowrap;
    text-overflow: ellipsis; */
}

.casting-call-desc p{
    word-break: break-word;
}

.casting-call-desc p a{
    color: #fff;
    text-decoration: none;
    word-break: break-word;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.casting-detail-read-btn{
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: right;
}

.avail-casting-call-header h3,
.avail-casting-call-header h3 a{
    font-family: "Bebas Neue", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #FBB03B;
    text-decoration: none;
    word-break: break-word;
    width: 50%;
}

.avail-casting-call-header h4{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #fff;
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    margin-top: 15px;
}

.avail-casting-call-body h5,
.avail-casting-call-footer h5{
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #fff;
    margin-bottom: 15px;
    margin-top: 20px;
    text-transform: capitalize;
    word-break: break-word;
}

.user-call-status span{
    background-color: #FBB03B;
    color: #000;
    font-weight: 500;
    font-size: 16px;
    padding: 5px;
}

.avail-casting-call-body p,
.avail-casting-call-footer p{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
}

.avail-casting-call-body p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: unset !important;
}

/*/--- filter menu section ---/*/
.offcanvas.filter-menu-wrapper{
    background-color: rgba(20, 20, 20, 1);
}

.filter-menu-wrapper .offcanvas-title{
    font-family: "Bebas Neue", sans-serif;
    font-size: 34px;
    color: #FBB03B !important;
}

.filter-menu ul{
    list-style: none;
}

.filter-menu ul li{
    width: 100%;
    height: 40px;
    margin-top: 10px;
}

.filter-menu .filter-link{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #000;
    text-decoration: none;
    text-transform: capitalize;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    padding: 0px 20px; 
    width: fit-content;
    height: 100%;
    background-color: #fff;
    transition: all 0.3s ease-in;
    border-radius: 20px;
}

.filter-menu .filter-link:hover,
.filter-menu .filter-link.active{
    color: #000;
    background-color: #FBB03B;
    font-weight: 500;
}

.filter-link.show{
    background-color: #FBB03B;
    color: #000;
}

.filter-menu-close-btn{
    background-color: #FBB03B !important;
    opacity: 1 !important;
}

.active-filters label{
    background-color: #FBB03B;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 20px;
    padding: 5px 10px;
}

.active-filters label svg{
    width: 10px !important;
    height: 10px !important;
    margin-left: 10px;
}

/*/--- Role description page ---/*/
.role-descrption-wrapper{
    /* height: 60vh; */
    padding-top: 100px;
    padding-bottom: 60px;
}

.role-descrition-content h3,
.role-descrition-content h3 a{
    font-family: "Bebas Neue", sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.05em;
    text-align: left;
    color: rgba(251, 176, 59, 1);
    margin-bottom: 0;
    text-decoration: none;
}

.call-user-title a{
    color: #fff;
    text-decoration: none;
}

.role-descrition-content h5{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 0;
    text-transform: capitalize;
}

.role-sevices{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.role-sevices .role-sevice-link{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
    /* width: 140px; */
    height: 46px;
    border: 1px solid #fff;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    margin-bottom: 10px;
    padding-left: 8px;
    padding-right: 8px;
}

.role-descrption-txt p,
.date-location-txt p{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
    margin-top: 15px;
    word-break: break-word;
}

/*/--- casting audition page ---/*/
/*/--- casting audition page --- casting-audition-wrapper section ---/*/

.casting-audition-wrapper{
    padding: 120px 0px 80px 0px;
}

.video-recorder{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    border: 1px solid #fff;
}

.video-caption{
    display: flex;
    align-items: center;
}

.caption-txt{
    font-family: 'Futura Md BT';
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
}

.caption-txt span{
    font-family:  "Roboto", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    margin: 0px 10px;
}

.secondary_btn{
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: #000 !important;
    border-radius: 0px !important;
}

.secondary_outline_btn{
    background-color: transparent;
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0px !important;
}

.video-recording-handlers button{
    height: 50px;
    width: 174px;
    font-family: "Roboto", sans-serif !important;
    font-size: 18px !important;
    font-weight: 900 !important;
    line-height: 27px;
}

.video-recorder-wrapper{
    position: relative;
    height: 70vh;
    border: 2px solid #ffff;
    border-radius: 6px;
}

.video-live{
    width: 100%;
    height: 100%;
}

.video-controls{
    position: absolute;
    bottom: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.video-controls .video-btn,
.video-controls .mic-btn{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.52) !important;
    margin: 0px 10px;
}

.video-controls .mic-btn.mute,
.video-controls .video-btn.video-stoped {
    background-color: #fff !important;
}

.video-controls .mic-btn.mute .mute-path,
.video-controls .video-btn.video-stoped .mute-path{
    fill: rgba(0, 0, 0, 0.52) !important;
}

.recoding-timer{
    position: absolute;
    bottom: 18px;
    left: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
}


/*/--- film pitching booth page ---/*/
/*/--- film pitching booth page --- banner section ---/*/
.banner-wrapper{
    height: 100vh;
    background-image: url('../images/cinema.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left;
}

.banner-wraper-inner-layer{
    background-color: rgba(0, 0, 0, 0.85);
}

.banner-wrapper h1{
    font-family: "Bebas Neue", sans-serif;
    font-size: 85px;
    font-weight: 400;
    line-height: 100px;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.banner-wrapper p{
    font-size: 25px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    color: rgba(133, 133, 133, 1);
}

/*/--- film pitching booth page --- success stories section ---/*/
.success-stories-wrapper{
    padding: 20px 0px 80px 0px;
}

.section-heading{
    font-family: "Bebas Neue", sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 98px;
    font-weight: 400;
    line-height: 80px;
    text-align: left;
    margin-bottom: 0px;
}

.section-sub-heading{
    font-family: "Bebas Neue", sans-serif;
    color: #FBB03B;
    font-size: 58px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-align: left;
    margin-bottom: 0px;
}

.success-story-card{
    background-color: #fff;
    padding: 20px;
    width: 100%;
    position: relative;
}

.success-img-wrapper{
    height: 250px;
    width: 100%;
}

.success-img-wrapper img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.success-img-wrapper video{
    object-fit: cover;
}

.success-card-footer h3{
    font-family: "Bebas Neue", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: rgba(133, 133, 133, 1);
    margin-bottom: 0;
}

.success-card-footer span{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #000;
}

.success-card-txt{
    height: 250px;
    overflow-y: auto;
    padding-right: 10px;
}

/* Styling the scroll bar for the specific element */
.success-card-txt::-webkit-scrollbar {
    width: 3px; /* Set the width of the scroll bar */
}

.success-card-txt::-webkit-scrollbar-thumb {
    background: #888; /* Set the color of the scroll thumb */
    border-radius: 10px; /* Set the border radius of the scroll thumb */
}

.success-card-txt::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set the color of the scroll thumb when hovered */
}

/* Optional: Styling the scrollbar track */
.success-card-txt::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the background color of the scroll track */
}

.success-card-txt p{
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(72, 72, 72, 1);
}

.success-card-footer h5{
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    /* text-align: center; */
    color: rgba(72, 72, 72, 1);
}

.success-card-footer p{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(72, 72, 72, 1);
}

.date-location-footer{
    position: absolute;
    right: 20px;
    bottom: 10px;
}

/*/--- film pitching booth page --- profile section ---/*/
.profile-wrapper{
    padding-top: 60px;
    padding-bottom: 50px;
}

.profile-content h2{
    font-family: "Bebas Neue", sans-serif;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
    font-size: 85px;
    font-weight: 400;
    line-height: 102px;
    text-align: center;
    margin-bottom: 0px;
}

.profile-content h4{
    font-family: "Bebas Neue", sans-serif;
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #FBB03B;
}

.profile-content-cards{
    display: flex;
    gap: 15px;
    /* justify-content: center; */
    flex-wrap: wrap;
    padding: 50px 0px;
}

.profile-card{
    width: 211px;
}

.profile-card-img{
    height: 200px;
    width: 100%;
}

.profile-card-img img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* object-position: top; */
}

.profile-card-footer{
    width: 100%;
    height: 50px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #3E3E41 0%, #1E1E1E 100%);
    text-transform: capitalize;
}

/*/--- film pitching booth page --- how it works section ---/*/
.how-it-works-wrapper{
    padding-bottom: 60px;
}

.work-process h2{
    font-family: "Bebas Neue", sans-serif;
    font-size: 38px;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
    color: #FBB03B;
    margin-bottom: 30px;
}

.process-step{
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #fff;
    height: 84px;
    width: 100%;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
}

.process-step span{
    font-weight: 600;
    color: #FBB03B;
}

.discover-wrapper{
    height: 310px;
    width: 100%;
    position: relative;
}

.discover-wrapper img{
    width: 100%;
    height: 100%;
}

.discover-heading{
    font-family: "Bebas Neue", sans-serif;
    font-size: 38px;
    font-weight: 400;
    line-height: 45px;
    text-align: center;
    color: #FBB03B;
    margin-bottom: 23px;
}

.discover-wrapper .primary_btn{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content !important;
}


/*/--- individual user profile page ---/*/
/*/--- individual user profile page --- profile banner section ---/*/
.no-video-wrapper{
    border: 1px solid #fff;
    width: 100%;
}

.no-video-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.user-profile-wrapper{
    padding-top: 120px;
    padding-bottom: 30px;
}

.user-about textarea{
    width: 100%;
    background: transparent;
    box-shadow: none !important;
    outline: none !important;
    height: 100px;
    padding: 10px;
    color: #fff;
}

.profile-banner-wrapper{
    width: 100%;
    height: 100%;
    background-color: rgba(38, 38, 38, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
}

.profile-banner-wrapper-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-profile-picture{
    height: 280px;
    width: 280px;
    position: relative;
    border-radius: 50%;
    border: 5px solid #fff;
}

.user-profile-picture img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.user-bio{
    margin-top: 50px;
    width: 200px;
    /* width: 100%; */
}

.user-name{
    font-family: "Bebas Neue", sans-serif;
    font-size: 30px; /* 36px */
    font-weight: 400;
    line-height: 36px;
    text-align: left;
    color: #FBB03B;
    margin-bottom: 10px;
}

.user-role{
    font-size: 21px;
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
    background: transparent;
    border: none !important;
    box-shadow: none !important;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    outline: none !important;
}

.user-role::placeholder{
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    color: #525252;
}

.user-awards{
    margin-top: 0px;
}

.awards-icon{
    height: 90px;
    width: 90px;
    cursor: pointer;
}

.awards-icon img{
    width: 100%;
    height: 100%;
}

.award-add-btn p{
    font-family: "Outfit", sans-serif;
    font-size: 28px; /* 32px */
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 0px;
}

.profile-clip .vjs-big-play-button{
    background: transparent !important;
    border: 3px solid #fff !important;
}

.profile-clip .cld-video-player .vjs-big-play-button{
    top: 75%;
    left: 15%;
}

.edit-profile-clip-caption .play-button{
    left: 50%;
    top: 50%;
    transform: translate(-50%), translateY(-50%);
}

.user-profile-clip-wrap .video-frame{
    height: 340px;
}

.user-video-caption{
    position: absolute;
    bottom: 20%;
    left: 24%;
    width: 46%;
}

.user-video-caption h3{
    font-family: "Outfit", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;   
    color: #fff; 
    margin-bottom: 0;
}

.user-video-caption span{
    font-family: "Outfit", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #fff;
}

.primary_outline_btn{
    background-color: transparent;
    border: 1px solid #FBB03B !important;
    color: #FBB03B !important;
    font-family: "Bebas Neue", sans-serif !important;
    font-size: 21px !important;
    font-weight: 400 !important;
    line-height: 27px;
    border-radius: 0px !important;
    width: 100%;
    height: 50px;
}

.primary_outline_btn:hover{
    background-color: #FBB03B !important;
    color: #000 !important;
}

.user-about h3{
    font-family: "Bebas Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    color: #FBB03B;
}

.user-about p{
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #fff;
    word-break: break-word;
}

.edit-about-wrapper button{
    height: 48px;
    font-family: "Roboto", sans-serif !important;
    width: 140px;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.highlights{
    background-color: rgba(217, 217, 217, 1);
    width: 100%;
    padding: 12px 5px;
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    text-align: left;
    color: #000;
    margin-top: 20px;
}

/*/--- individual user profile page --- event section ---/*/
.event-wrapper{
    padding: 30px 0px;
}

.dark_btn{
    background-color: rgba(56, 56, 56, 1) !important;
    border: 1px solid rgba(56, 56, 56, 1) !important;
    color: #fff !important;
    border-radius: 0px !important;
    width: 100%;
    height: 60px;
    font-family: "Bebas Neue", sans-serif !important;
    font-size: 21px !important;
    font-weight: 400 !important;
    line-height: 27px;
}

.event_title{
    height: 50px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
    font-family: "Bebas Neue", sans-serif;
    font-size: 22px;
    font-weight: 400;
    color: #000;
    background-color: #FBB03B;
}

.table-wrapper table td,
.table-wrapper table th,
.table-wrapper table td a{
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
    color: #fff;
    border: none;
}

.table-wrapper table tr:nth-child(odd) td{
    background-color: rgba(56, 56, 56, 1);
    padding: 15px 20px;
}

.table-wrapper table tr:nth-child(even) td{
    background-color: #000;
    padding: 15px 20px;
}

/*/--- individual user profile page --- media section ---/*/
.media-wrapper{
    padding: 50px 0px;
}

.media-tabs .nav-tabs{
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.media-tabs .nav-tabs .nav-link{
    height: 44px;
    /* width: 150px; */
    border: 1px solid #fff;
    color: #fff;
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 40px;
    text-align: center;
    border-radius: 0px !important;
    padding-top: 0.1rem;
    padding-bottom: 0.2rem;
}

.media-tabs .nav-tabs .nav-link.active{
    background-color: #FBB03B !important;
    border: 1px solid #FBB03B !important;
    font-weight: 600 !important;
}

.media-card-wrapper{
    margin-top: 30px;
    position: relative;
}

.media-tabs-wrapper{
    position: relative;
}

.media-left-layer{
    position: absolute;
    width: 15%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #000000 27.8%, rgba(0, 0, 0, 0.19) 100%);
}

.media-right-layer{
    position: absolute;
    width: 15%;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(90deg, #000000 28.05%, rgba(0, 0, 0, 0.19) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.media-img{
    width: 100%;
    height: 170px;
    display: flex;
}

.media-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

#photos_uploaded{
    margin-top: 5px;
    list-style: none;
    font-size: 12px;
    line-height: 20px;
}

.media-caption{
    font-family: "Bebas Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #fff;
    margin-top: 20px;
    word-break: break-word;
}

/*/--- edit user profile page --- event section ---/*/
.add-event-wrapper .primary_btn,
.upload-gallery .primary_btn{
    width: 170px;
    font-family: "Roboto", sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    /* height: 44px; */
}

.edit-profile-sidebar{
    /* padding-top: 120px; */
    position: relative;
    width: 100%;
}

.profile-sidebar-toggler{
    color: #fff !important;
    border: 1px solid #fff !important;
    border-radius: 0px !important;
}

.profile-edit-controls .primary_outline_btn,
.profile-edit-controls .secondary_outline_btn{
    width: 100%;
    /* height: 47px; */
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: unset !important;
    text-align: left;
    margin-bottom: 20px;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
}

.user-profile-roles-show{
    font-size: 0.9rem;
}

.profile-edit-controls .primary_outline_btn{
    font-weight: 700 !important;
}

.profile-banner{
    background-color: rgba(38, 38, 38, 1);
    height: 100%;
}

.profile-banner-footer{
    padding: 30px 30px;
}

.profile-banner-footer h3{
    font-family: "Bebas Neue", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #fff;
    margin-bottom: 0;
}

.profile-banner-footer h3 span{
    color: #FBB03B;
}

.profile-banner-footer p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-align: left;
    color: #fff;
}

.profile-banner{
    padding: 30px 20px;
}

.profile-banner .user-profile-picture {
    height: 180px;
    width: 180px;
}

.user-role-wrapper{
    position: relative;
    width: 100%;
    /* margin-top: 40px !important; */
}

.edit-role{
    position: absolute;
    right: -40px;
    top: 0px;
    /* transform: translate(-50%, -50%); */
}

.edit-profile-picture{
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 2%;
    right: 4%;
}

.user-about-wrapper{
    position: relative;
}

/* .user-profile-roles-wrapper{
    padding-top: 20px;
} */
.user-profile-roles-wrapper{
    flex-direction: row;
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
}

.user-profile-roles-wrapper li{
    text-decoration: none !important;
    list-style: none !important;
    display: inline;
}

.user-profile-roles-wrapper li svg{
    margin-right: 5px;
    margin-left: 5px;
}

.edit-about-btn{
    display: flex;
    align-items: center;
    text-decoration: none;
    position: absolute;
    top: -35px;
    right: 0;
}

.edit-about-btn span{
    font-family: "Outfit", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: rgba(146, 146, 146, 1);
    display: inline-block;
    margin-left: 10px;
}

.user-bio-video-clip{
    position: relative;
}

.user-bio-video-clip .video-frame{
    height: 340px;
}

.edit-bio-video-clip{
    position: absolute;
    right: 30px;
    bottom: 30px;
}

/*/------ edit profile modals ---------/*/
.upload-photo-modal-body-content{
    padding: 50px 0px;
}

.photos-upload-modal .modal-content{
    background-color: #000 !important;
    border-radius: 0px !important;
}

.upload-photo-modal-body-content{
    font-family: "Bebas Neue", sans-serif;
    font-size: 35px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    color: #fff;
}

.upload-photo-modal-body-content .secondary_outline_btn{
    width: 180px;
    height: 50px;
    font-family: "Roboto", sans-serif !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}

.modal-backdrop{
    background-color: rgb(64, 64, 64) !important;
}

.upload-photo-modal-body-content .primary_btn{
    font-family: "Roboto", sans-serif !important;
    width: 60%;
}

.close_modal_btn{
    background-image: url('../images/close_btn.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -12px;
    right: -16px;
    width: 40px;
    height: 40px;
    border: none !important;
}

.film-upload-content input,
.film-upload-content textarea{
    background: transparent !important;
    width: 100%;
    border: 1px solid #fff !important;
    border-radius: 0px !important;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Roboto", sans-serif !important;
    padding: 10px;
}

.film-upload-content input{
    height: 50px;
}

.film-upload-content label{
    background: transparent !important;
    width: 100%;
    border: 1px solid var(--lightColor) !important;
    border-radius: 0px !important;
    color: var(--lightColor);
    font-size: 16px;
    font-weight: 400;
    font-family: var(--roboto-family) !important;
    height: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: left;
}

.film-upload-content input::placeholder,
.film-upload-content textarea::placeholder{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff !important;
    font-family: "Roboto", sans-serif !important;
}

.film-upload-content p{
    font-size: 12px;
    color: #fff;
    text-align: left;
    line-height: 26px;
    margin-bottom: 0;
}

.film-upload-content .primary_btn{
    height: 50px;
}


/*/---- creating casting calls page ----/*/
.page-heading-wrapper{
    padding-top: 100px;
}

.page-heading{
    font-family: "Bebas Neue", sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.05em;
    text-align: left;
    color: #FBB03B;
}

.casting-calls-form input{
    border: 1px solid #fff;
    border-radius: 0px !important;
    width: 100%;
    height: 50px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    background: #000 !important;
    padding: 10px;
    outline: none !important;
    box-shadow: none !important;
}

.casting-calls-form .roles-select-wrapper,
.contact-us-form .roles-select-wrapper{
    border: 1px solid #fff;
    border-radius: 0px !important;
    width: 100%;
    height: 50px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    background: #000 !important;
    outline: none !important;
    box-shadow: none !important;
}

.setting-form-input .roles-select-wrapper{
    border: 1px solid #fff;
    border-radius: 0px !important;
    width: 100%;
    height: 50px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    background: rgba(20, 20, 20, 1) !important;
    outline: none !important;
    box-shadow: none !important;
}

.setting-form-input .roles-select-wrapper .select__control{
    background: rgba(20, 20, 20, 1) !important;
}

.roles-select-wrapper .select__control{
    height: 100%;
    width: 100%;
    background: #000 !important;
    border: none !important;
    outline: none !important;
    color: #fff !important;
}

.roles-select-wrapper .select__control--is-focused{
    outline: none !important;
    box-shadow: none !important;
}

.roles-select-wrapper .css-1jqq78o-placeholder{
    margin-left: 10px !important;
    color: #fff !important;
    /* margin-top: -10px; */
}

.select__input{
    color: #fff !important;
}

.roles-select-wrapper .select__value-container{
    height: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center !important;
} 

.roles-select-wrapper .select__multi-value{
    background-color: #FBB03B !important;
}

.roles-select-wrapper .css-qbdosj-Input {
    padding: 0px !important;
    margin: 0px !important;
}

.roles-select-wrapper .select__menu{
    background-color: #000 !important;
    border: 1px solid #fff;
    border-radius: 0px !important;
    color: #fff !important;
}

.casting-calls-form input::placeholder,
.casting-calls-form textarea::placeholder{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
}

.casting-calls-form textarea{
    border: 1px solid #fff;
    border-radius: 0px !important;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    background: #000 !important;
    padding: 10px;
    outline: none !important;
    box-shadow: none !important;
}

.casting-calls-form button{
    height: 50px !important;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.user-calls-wrapper{
    padding: 60px 0px;
} 

.edit-calls-menu{
    position: absolute;
    top: 10px;
    right: 20px;
}

.edit-calls-menu-list{
    position: absolute;
    top: 40px;
    right: 15px;
    background-color: #fff;
    width: 110px;
}

.edit-calls-menu-list a{
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    background-color: #fff;
    text-decoration: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.edit-calls-menu-list a:hover{
    background-color: #FBB03B !important;
    color: #fff !important;
    font-weight: 500 !important;
}

/*/---- user film picthing booth page ----/*/

.view-vision-btn .primary_outline_btn{
    font-family: "Roboto", sans-serif !important;
    height: 30px;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 13px;
}

.user-film-booth-wrapper{
    padding: 0px 0px 60px 0px;
}

.user-film-booth-wrapper .video-frame {
    height: 170px;
}

.user-film-booth-wrapper .video-layer{
    height: 86%;
}

.talent-frame-wrapper .primary_outline_btn{
    height: 30px;
    font-family: "Roboto", sans-serif !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 13px;
    padding-left: 10px;
    padding-right: 10px;
}
/*/--- video player ---/*/
.video-player-container {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid #fff;
    padding: 1px;
   /* overflow: hidden; */
}
.edit-video-player div:first-child{
    height: 100%  !important;
    object-fit: cover;
}

.edit-profile-clip-caption{
    height: max-content !important;
}

.edit-video-player div:first-child{
    height: 100%  !important;
    object-fit: cover;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 18px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}
  
/*/---- membership page ----/*/
.membership-wrapper{
    padding: 120px 0px 100px 0px;
}

.membership-wrapper button{
    height: 50px;
    font-family: "Roboto", sans-serif !important;
    font-size: 16px !important;
    font-weight: 700 !important;
}

.monthly-package-wrapper{
    padding-bottom: 60px;
}

.monthly-package-content{
    position: relative;
    width: 100%;
    /* height: 568px; */
    text-align: center;
    background: linear-gradient(180deg, #2C2C31 0%, #121212 100%);
}

.monthly-package-card-body{
    background-image: url('../images/package-bg.png');
    background-repeat: no-repeat;
    padding: 20px;
    height: 100%;
}

.top-circle{
    width: 26px;
    height: 26px;
    background-color: #FBB03B;
    border-radius: 50%;
    border: 2px solid #fff;
    position: absolute;
    top: 10px;
    right: 10px;
}

.package-name h2{
    font-size: 86px;
    font-weight: 700;
    line-height: 94px;
    color: #fff;
    margin-top: 40px;
}

.package-name h2 span{
    font-size: 18px;
    line-height: 21px;
}

.package-name h3{
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: #fff;
    margin-top: 15px;
}

.package-duration h4{
    font-size: 24px;
    font-weight: 900;
    line-height: 24px;
    text-align: center;
    color: #fff;
}

.package-duration p{
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #fff;
    margin-bottom: 0;
}

.package-duration p .sterik{
    font-size: 20px !important;
    font-weight: 400 !important;
    color: red;
}

.package-duration p span{
    font-weight: 900;
}

.monthly-package-card-body button{
    text-transform: uppercase;
    font-size: 18px !important;
    width: 60% !important;
}

/*/---- subscription page ----/*/
.home-banner.subscribe-banner{
    background-image: url('../images/banner-bg2.png');
}

.subscription-wrapper{
    padding: 60px 0px;
}

.subscription-wrapper-content{
    padding-bottom: 60px;
}

.section-txt{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    color: #fff;
    margin-top: 30px;
}

/* package modal */
.subscrition-modal-body-content{
    padding: 60px;
}

.subscrition-modal .modal-content{
    background-color: #FBB03B !important;
    border-radius: 0px !important;
    color: #000 !important;
}

.subscrition-modal .close_modal_btn{
    background-image: url('../images/close_btn.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: -12px;
    right: -16px;
    width: 40px;
    height: 40px;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.subscrition-modal-body-content h2{
    font-family: "Bebas Neue", sans-serif;
    font-size: 102px;
    font-weight: 400;
    line-height: 100px;
    text-align: center;
}

.subscrition-modal-body-content p{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
}

.subscribe-btn{
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 26px;
    color: #000 !important;
    border: 1px solid #000 !important;
    background: transparent;
    border-radius: 0px !important;
    text-transform: uppercase;
    height: 60px;
    width: 200px;
}


.user-profile-roles-input{
    background-color: #000 !important;
    border-radius: 0px !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.user-profile-roles-input .css-13cymwt-control, 
.user-profile-roles-input .css-t3ipsp-control{
    background-color: #000 !important;
    border-radius: 0px !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
}

.user-profile-roles-input .css-1nmdiq5-menu{
    background-color: #000 !important;
    border-radius: 0px !important;
    border: 1px solid #fff !important;
    color: #fff !important;
}

.user-profile-roles-input .css-3w2yfm-ValueContainer{
    flex-wrap: nowrap !important;
}
.media-card-wrapper {
    position: relative;
  }
  
  .delete-icon {
    position: absolute;
    top: -10px;
    right: -10px; 
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 1;
    /* background-color: #ddd; */
  }
  
.duration-text{
    text-transform: uppercase !important;
}

.home-banner.video-hub-banner{
    background-image: url('../images/banner-bg6.png');
    padding-top: 0px !important;
    background-position: left -22px top !important;
}

.video-banner-layer{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.88);
}

.audition-link{
    justify-content: center;
    text-align: right;
    text-decoration: none !important;
}

.text-under{
    text-decoration: none !important;
}

.empty-response-wrapper{
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-response-wrapper p{
    margin-bottom: 0;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

input[type="time"]::-webkit-calendar-picker-indicator {
    filter: invert(1); 
}

.preview-container{
    border: 1px solid #fff;
    width: fit-content;
}

#rfs-btn{
    border: none;
}

.ReactFlagsSelect-module_selectBtn__19wW7{
    color: #fff !important;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="false"]:after{
    border-top: 5px solid #fff !important;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after{
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff !important;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 10px;
}

#rfs-btn .ReactFlagsSelect-module_label__27pw9{
    color: #fff !important;
}

.ReactFlagsSelect-module_label__27pw9{
    color: #000 !important;
}

.ReactFlagsSelect-module_flagsSelect__2pfa2{
    padding-bottom: 0px !important;
}

.menu-flags input{
    height: 30px;
    border-radius: 0px;
    border: 1px solid #000;
}

.ReactFlagsSelect-module_selectFlag__2q5gC{
    display: none;
}

.language-input{
    font-size: 18px;
    font-weight: 400;
    color: #ffff;
    text-decoration: none;
    text-transform: capitalize;
    line-height: 21px;
    text-align: left;
    margin-right: 20px;
}

.language-input:hover{
    color: #FBB03B;
}

/*/------ privacy ploicy page ------/*/
.privacy-policy-wrapper{
    padding: 60px 0px;
}

.privacy-policy-card{
    width: 100%;
    height: 100%;
    padding: 60px 40px;
}

.privacy-policy-card .section-heading{
    font-size: 58px;
    line-height: 48px;
    text-align: center;
}

.privacy-policy-card .section-sub-heading{
    font-size: 30px;
    line-height: 33px;
    text-align: center;
}

.policy-content h3{
    font-size: 24px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0px;
}

.privacy-policy-card p{
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0px;
    margin-top: 20px;
}

.policy-content ul{
    list-style: none;
    margin-top: 20px;
}

.policy-content ul li{
    padding: 10px 0px;
    line-height: 22px;
    font-weight: 400;
    font-size: 16px;
}

.policy-content ul li span{
    color: #FBB03B;
    font-weight: 600;
}

.policy-content p a{
    color: #FBB03B;
    display: block;
    margin-top: 5px;
}

.privacy-content a{
    margin-left: 0px !important;
    text-transform: capitalize;
}

.video-desc{
    width: 70%;
}

.video-link{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.video-link-btn{
    width: 70%;
}

.download-btn{
    text-decoration: none !important;
    border-bottom: 3px solid #FBB03B;
    margin-top: 10px;
}

.download-btn svg{
    width: 20px;
    height: 20px;
    fill: #FBB03B;
}

/*/--- contact us page -- start here ---/*/
.home-banner.contact-us-banner{
    background-image: url('../images/contact-banner-bg.png');
}

.contact-us-wrapper{
    padding: 60px 0px;
}

.contact-us-img-wrapper{
    width: 100%;
}

.contact-us-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.contact-form-wrapper h3{
    font-family: "Bebas Neue", sans-serif;
    font-size: 45px;
    font-weight: 400;
    line-height: 54px;
    letter-spacing: 0.05em;
    text-align: center;
    color: rgba(251, 176, 59, 1);
}

.contact-form-wrapper p{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    color: #fff;
    text-align: center;
}

.contact-us-form input,
.contact-us-form select,
.contact-us-form textarea{
    border: 1px solid #fff;
    border-radius: 0px !important;
    width: 100%;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
    background: #000 !important;
    padding: 10px;
    outline: none !important;
    box-shadow: none !important;
    margin-top: 30px;
}

.contact-us-form input,
.contact-us-form select{
    height: 50px;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #fff;
}

/*/--- contact us page -- end's here ---/*/
.award-info{
    display: flex;
    justify-content: center;
}

.award-add-btn a{
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-left: 10px;
}

.award-add-btn a img{
    width: 100%;
    height: 100%;
    margin-top: -7px;
}

.awards-icon{
    position: relative;
}

.awards-desc-wrapper{
    width: 300px;
    padding: 20px 10px;
    text-align: center;
    background-color: #fff;
    border-radius: 8px;
    display: none;
    position: absolute;
    z-index: 999;
    box-shadow: 2px 2px 5px 2px #242222;
}

.awards-desc-wrapper:hover{
    display: block;
}

/* Select the first .awards-desc-wrapper */
.awards-desc-wrapper {
    left: -100px;
    bottom: 90px;
    max-height: 150px;
    overflow-y: scroll;
}

.awards-desc-wrapper::-webkit-scrollbar {
    width: 3px; /* Set the width of the scroll bar */
}

.award-menu{
    position: absolute;
    top: -10px;
    right: 0px;
}

.edit-calls-menu-list.award-menu-list{
    top: 15px;
    right: 0;
    background-color: rgba(38, 38, 38, 1);
}

.edit-award-btn{
    color: #fff !important;
    background-color: rgba(38, 38, 38, 1) !important;
    padding: 2px 8px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
}

.del-award-btn{
    color: #fff !important;
    background-color: rgba(38, 38, 38, 1) !important;
    padding: 2px 8px;
    text-decoration: none;
    font-size: 12px;
    font-weight: 400;
}

/* Select the second .awards-desc-wrapper
.awards-desc-wrapper:nth-of-type(2) {
    right: -200px !important;
    top: -80px;
}


.awards-desc-wrapper:nth-of-type(3) {
    right: -300px !important;
    top: -60px;
} */

.awards-desc-wrapper:nth-of-type(3):hover,
.awards-desc-wrapper:nth-of-type(2):hover,
.awards-desc-wrapper:nth-of-type(1):hover{
    z-index: 9999 !important;
}

.award-details {
    padding-bottom: 5px;
    padding-top: 15px;
    border-bottom: 1px solid #ddd; /* Adjust the color as needed */
}
  
.award-pointer{
    width: 15px;
    height: 15px;
    background-color: #fff;
    rotate: 45deg;
    bottom: -27px;
    left: 7px;
    position: absolute;
}

.awards-desc-wrapper .award-title{
    font-family: "Bebas Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: rgba(251, 176, 59, 1);
    margin-bottom: 5px;
    word-break: break-word;
}

.awards-desc-wrapper .awards-desc{
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: rgba(0, 0, 0, 1);  
    word-break: break-word; 
}

.awards-icon:hover .awards-desc-wrapper{
    display: block;
}

.css-1dyz3mf{
    flex-wrap: nowrap !important;
}


/*/---- chat screen start here ----/*/
.chat-screen-wrapper{
    padding: 90px 0px 60px 0px;
}

.chat-screen-content h2{
    font-family: "Bebas Neue", sans-serif;
    color: #FBB03B;
    font-size: 58px;
    font-weight: 400;
    line-height: 60px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-bottom: 0px;
}

.users-details-wrapper, .users-chats-wrapper{
    background-color: rgba(30, 30, 30, 1);
    width: 100%;
    height: 450px;
    overflow-y: scroll;
}

.users-details-wrapper{
    padding: 20px 15px 20px 20px;
    /* height: 100%; */
}

.single-user-detail-card{
    border-left: 2px solid #FBB03B;
    width: 100%;
    height: 70px;
    background-color: #2C2C2C;
    cursor: pointer;
}

.single-user-detail-card.active{
    background-color: #8f744c;
}

.single-user-dp{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.single-user-dp img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.single-user-desc{
    width: 100%;
    position: relative;
}

.single-user-name{
    color: #FBB03B;
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.last-message{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    font-weight: 400;
}

.last-seen{
    position: absolute;
    right: -7px;
    bottom: -9px;
    color: #565656;
    font-size: 12px;
    font-weight: 400;
}

.users-chats-wrapper{
    position: relative;
    padding: 20px;
}

.typing-input-wrapper{
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 97%;
    height: 50px;
    background-color: rgba(30, 30, 30, 1);
}

.typing-input-wrapper input{
    height: 100%;
    width: 80%;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #fff;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    padding: 10px;
}

.typing-input-wrapper .send-btn{
    width: 20%;
    height: 100%;
    background-color: #FBB03B;
    color: #000;
    font-size: 18px;
    font-weight: 500;
    border: 1px solid #FBB03B;
}

.typing-input-wrapper .send-btn:disabled {
    cursor: not-allowed;
    opacity: 0.6; 
}

.chat-card-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    position: absolute;
    top: 0;
    width: 97%;
    background-color: rgba(30, 30, 30, 1);
}

.settings a{
    display: block;
    width: 10px;
}

.active-dot{
    width: 8px;
    height: 8px;
    background-color: #FBB03B;
    border-radius: 50%;
    display: inline-block;
    margin-left: 6px;
}

.chat-card-body{
    height: 350px !important;
    overflow-y: scroll;
}
.chat-user{
    padding-top: 30px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.reciever-message-content, .sender-message-content{
    max-width: 70%;
    width: fit-content;
    background-color: #FBB03B;
    color: #000;
    border-radius: 8px;
    padding: 8px;
    word-break: break-word;
}

.reciver, .sender{
    width: 100%;
    display: flex;
    margin-bottom: 15px;
}

.reciver{
    justify-content: flex-start;
}

.sender{
    justify-content: flex-end;
}

.reciever-img, .sender-img{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.reciever-img img, .sender-img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.active-user-desc{
    text-transform: capitalize;
    font-size: 22px;
    font-weight: 500;
    color: #FBB03B;
    font-family: "Bebas Neue", sans-serif;
    display: flex;
    align-items: center;
}

/*/---- chat screen end's here ----/*/

/*/---- Notification page start here ----/*/
.notification-wrapper{
    padding: 100px 0px 80px 0px;
}

.notification-card{
    background-color: rgba(30, 30, 30, 1);
    padding: 20px;
    border-radius: 8px;
    margin: 20px 0px;
}

.notification-title{
    color: #FBB03B;
    font-family: "Bebas Neue", sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
}

.notification-msg{
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
}

.no-notification{
    height: 50vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-link{
    position: relative;
}

.notification-dropdown{
    position: absolute;
    top: 36px;
    right: 0;
    background-color: #000;
    box-shadow: 0 2px 12px 0 #2C2C2C; /*8f744c*/
    width: 300px;
    max-height: 300px;
    overflow-y: scroll;
}

.notification-dropdown ul{
    list-style: none !important;
    padding-left: 0px;
    margin-bottom: 0px;
}

.notification-item{
    border-bottom: 1px solid #FBB03B;
}

.notification-item:last-child {
    border-bottom: none;
}

.notification-item a{
    padding: 15px 10px;
    color: #fff !important;
    text-decoration: none !important;
    width: 100%;
    display: block;
}

.notification-item a:hover{
    background-color: #8f744c;
}

.notification-time{
    color: #FBB03B;
    font-size: 12px;
    font-weight: 300;
    text-align: right;
}

.notification-time span{
    color: #FBB03B;
}

.notificatin-counter{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: #000;
    background-color: #FBB03B;
    border-radius: 50%;
    position: absolute;
    top: -11px;
    right:-12px;
    font-size: 12px;
    font-weight: 500;
    width: 25px;
    height: 25px;
}

.read-all-btn{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.read-all-btn a{
    text-decoration: none;
    color: #FBB03B !important;
}

.video-placeholder-wrapper{
    height: 100%;
    width: 100%;
    /* border: 1px solid #fff; */
}

.video-placeholder-wrapper img{
    height: 100%;
    width: 100%;
}

/*/---- Notification page end's here ----/*/
.confirmation-modal .modal-footer{
    border-top: 0px !important;
    justify-content: center;
}

.confirmation-txt{
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
}

.confirmation-btn{
    width: 30%;
    margin: 0.7rem !important;
}

.confirmation-btn.secondary_outline_btn {
    font-size: 22px !important;
    font-weight: 400 !important;
    font-family: "Bebas Neue", sans-serif !important;
}

.confirmation-modal .modal-content{
    background-color: #000 !important;
    border-radius: 0px !important;
}

/* pagination styles */
.pagination-wrapper .page-item .page-link{
    background-color: #fff !important;
    border-color: #000 !important;
    color: #000 ;
    cursor: context-menu;
}

.pagination-wrapper .page-item .page-link:hover{
    background-color: #FBB03B !important;
    border-color: #FBB03B !important;
    color: #000 ;
}

.pagination-wrapper .page-item.active .page-link{
    background-color: #FBB03B !important;
    border-color: #FBB03B !important;
}

.input-label{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left !important;
    color: #fff !important;
    font-family: "Roboto", sans-serif !important;
    display: block;
    margin-bottom: 5px;
}

.noti-del-btn{
    color: #FBB03B;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.file-restriction-txt{
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
}

.upload-photo-modal-body-content h3{
    font-size: 35px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    margin-bottom: 0px;
}

.auditon-alert-msg{
    color: #FBB03B;
    font-family: 'Roboto',sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
}


/*/---- settings page starts here ----/*/

.settings-wrapper{
    padding: 110px 0px 60px 0px;
}

.settings-card{
    background-color: rgba(5, 6, 8, 1);
    padding: 40px 30px;
}

.settings-card h2{
    font-family: "Bebas Neue", sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 58px;
    text-align: center;
    color: rgba(255, 255, 255, 1);
}

.settings-card form{
    margin-top: 30px;
}

/*/---- settings page end's here ----/*/

#rc-anchor-container{
    background: #fff !important;
}

.delete-profile-btn{
    background-color: rgba(0, 0, 0, 1);
    color: #fff;
    font-weight: 600;
    font-size: 36px;
    line-height: 16px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    position: absolute;
    left: 10px;
    bottom: 2%;
}  

.grecaptcha-badge { visibility: hidden; }


/*/--- verify email styles start here ---/*/
.email-template-wrapper{
    height: 100vh;
    width: 100%;
    color: #fff;
}

.email-template-wrapper h2{
    color: #FBB03B;
}

.email-template-wrapper h2 svg{
    width: 36px;
    height: 36px;
    fill: #FBB03B;
    margin-right: 5px;
}

.warning-msg{
    color: #FBB03B;
    text-align: center;
    font-size: 22px;
    line-height: 16px;
    font-weight: 500;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 0.5px;
}

.share-btn{
    background-color: #FBB03B !important;
    border: 1px solid #FBB03B !important;
    border-radius: 4px;
    padding: 13px 19px !important;
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 18px;
    color: #000000 !important;
    position: absolute;
    right: 0;
    top: 30px;
}

.copy-url-wrapper input{
    padding-right: 38px;
    box-shadow: none !important;
    outline: none !important;
    border-radius: none !important;
}

.copy-url-wrapper a{
    position: absolute;
    top: 7px;
    right: 10px;
}

.copy-url-wrapper span{
    font-size: 16px;
    font-weight: 500;
    color: #565656;
    font-family: "Roboto", sans-serif;
    line-height: 20px;
}

.large-video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  