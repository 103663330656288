@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primaryColor: #FBB03B;
    --secondaryColor: #000;
    --lightColor: #fff;
    --baseBorderRadius: 0px;
}

#root{
    background-color: #000 !important;
}

.srch-input-wrapper{
    border: 1px solid #fff;
    border-radius: 0px;
    height: 40px;
    margin-bottom: 20px;
    position: relative;
}

.srch-input-wrapper input{ 
    border: none !important;
    border-radius: 0px !important;
    height: 100%;
    color: #fff !important;
    outline: none !important;
    box-shadow: none !important;
    background: transparent !important;
    padding: 5px 10px;
}

.srch-input-wrapper .srch-btn-link{
    position: absolute;
    right: 10px;
    top: 6px;
    color: #fff;
    background-color: #141414;
}

.srch-input-wrapper .srch-btn-link svg{
    width: 20px;
    height: 20px;
    fill: #fff !important;
}

.yellow-txt{
    color: #FBB03B;
    text-transform: capitalize;
    font-weight: 600;
}

.ReactCrop__child-wrapper img{
    width: 300px;
    height: 250px;
    object-fit: contain;
}

.reactEasyCrop_Contain{
    /* margin: 0px !important; */
}


.overlay-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7); 
}

.overlay-spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Black background with opacity */
    z-index: 9999; /* Ensure it appears above everything */
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden; /* Hide by default */
    opacity: 0;
    transition: visibility 0s, opacity 0.3s; /* Smooth fade-in/out */
  }
  
  .overlay-spinner.active {
    visibility: visible;
    opacity: 1;
    
  }
  
  .chat-screen-content {
    opacity: 1;
    transition: opacity 0.3s;
  }
  
  .chat-screen-content.loading {
    opacity: 0.3; /* Reduce opacity when loading */
    pointer-events: none; /* Disable interactions when loading */
  }
  

  


.chat-screen-wrapperloader{
    height: 450px;
}
