.spinner-container.login-spinner-container {
    height: 100% !important;
  }
  
  .spinner.login-spinner {
    border: 5px solid rgba(0, 0, 0, 0.5) !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    border-left-color: #000 !important;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  