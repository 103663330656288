
/*/--- Blog Banner Section Start's Here ---/*/
.blog-banner-wrapper{
    height: 100vh;
    width: 100%;
}

.blog-banner-content{
    background-image: url('../images/blog-banner-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 12px;
}

.header-navbar.blog-nav{
    position: inherit !important;
}

.blog-banner-inner-layer{
    width: 100%;
    background-color: rgba(2, 1, 1, 0.8);
}

.blog-banner-content h1{
    font-family: "Bebas Neue", sans-serif;
    font-size: 85px;
    font-weight: 400;
    line-height: 100px;
    text-align: center;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.blog-banner-content p{
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 34px;
    text-align: center;
    color: #D1D1D1;
}
/*/--- Blog Banner Section End's Here ---/*/


/*/--- Blogs Listing Section Start's Here ---/*/
.blog-listing-section{
    padding: 80px 0px;
}

.blog-card-wrapper{
    width: 100%;
}

.blog-card-img{
    width: 100%;
    height: 300px;
    background-color: #23201f;

}

.blog-card-img img{
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}

.blog-by-txt p{
    font-family: "Roboto",sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;
}

.blog-by-txt p span{
    color: #FBB03B;
}

.blog-by-txt h2,
.blog-by-txt h2 a{
    font-family: "Bebas Neue", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.7px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
}

.blog-details p{
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #D1D1D1;

    display: -webkit-box; /* Use a flexbox-like behavior */
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical; /* Set box orientation to vertical */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Add ellipsis */
}


/* pagination styles */
.pagination-wrapper .page-item .page-link{
    background-color: #fff !important;
    border-color: #000 !important;
    color: #000 ;
    cursor: context-menu;
}

.pagination-wrapper .page-item .page-link:hover{
    background-color: #FBB03B !important;
    border-color: #FBB03B !important;
    color: #000 ;
}

.pagination-wrapper .page-item.active .page-link{
    background-color: #FBB03B !important;
    border-color: #FBB03B !important;
}
/*/--- Blogs Listing Section End's Here ---/*/


/*/--- Blog Detail Page Start's Here ---/*/
.blog-detail-page-wrapper{
    padding: 80px 0px;
}

.bread-crumbs p,
.bread-crumbs p a{
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
}

.blog-title{
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.blog-title h2{
    font-family: "Bebas Neue",sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 70px;
    text-align: left;
    color: #FFFFFF;
}

.blog-title p{
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #D1D1D1;
}

/*/--- Blog Slider Styles Start's Here ---/*/
.blog-slider-wrapper{
    height: 400px;
}

.blog-slider-wrapper img{
    height: 400px;
    object-fit: contain;
    background-color: #23201f;
}

.blog-slider-wrapper .carousel-indicators button{
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
    background-color: #D9D9D9;
    opacity: 1 !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
}

.blog-slider-wrapper .carousel-indicators button.active{
    background-color: #FBB03B;
}

/*/--- Blog Slider Styles End's Here ---/*/

.blog-txt-details p,
.blog-txt-details{
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #A3A3A3;
}

.blog-txt-details a{
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #FBB03B;
}

.blog-txt-details iframe{
    width: 100% !important;
    margin-top: 40px;
}

.blog-txt-details img{

    margin-top: 30px;
    margin-bottom: 20px;

}

.quote-txt{
    display: flex;
    align-items: center;
}

.quote-txt p{
    font-family: "Roboto",sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 0;
}

.blog-gallery-img{
    width: 100%;
    height: 250px;
}

.blog-gallery-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*/--- Relevent blogs Styles Start's Here ---/*/
.relevent-blogs-wrapper{
    width: 100%;
}

.relevent-blogs-wrapper h2{
    font-family: "Bebas Neue",sans-serif;
    font-size: 64px;
    font-weight: 400;
    line-height: 70px;
    text-align: left;
    color: #FFFFFF;
}

.relevent-blog-card-wrapper{
    width: 100%;
}

.relevent-blog-img{
    width: 100%;
    height: 250px;
    background: url('../../assets/images/blogs/no-image.png');
}

.relevent-blog-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.relevent-blog-card-wrapper h3,
.relevent-blog-card-wrapper h3 a{
    font-family: "Bebas Neue",sans-serif;
    font-size: 48px;
    font-weight: 400;
    line-height: 53px;
    text-align: left;
    color: #FFFFFF;
    text-decoration: none;
}

.relevent-blog-card-wrapper p{
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    text-align: left;
    color: #D1D1D1;
}
/*/--- Relevent blogs Styles End's Here ---/*/

/*/--- Blog Detail Page End's Here ---/*/


@media(max-width: 768px){
    .blog-banner-wrapper{
        height: 60vh;
    }

    .blog-banner-content h2{
        font-size: 28px;
        line-height: 30px;
    }

    .blog-banner-content p{
        font-size: 13px;
        line-height: 20px;
    }

    .blog-by-txt h2,
    .blog-by-txt h2 a {
        font-size: 20px;
        line-height: 30px;
    }

    .blog-details p{
        font-size: 13px;
        line-height: 20px;
    }

    .quote-txt{
        flex-wrap: wrap;
    }

    .blog-slider-wrapper {
        height: 250px;
    }

    .blog-slider-wrapper .carousel,
    .blog-slider-wrapper .carousel-inner,
    .blog-slider-wrapper .carousel-item {
        height: 100%;
    }
}


.sticky-sidebar {
    position: sticky;
    top: 20px; /* Adjust the top offset as needed */
    align-self: flex-start; /* Ensures it sticks properly in the grid */
    z-index: 10; /* Optional: Adjust if overlapping issues arise */
  }
  .blog-card-content h2 {
   
    display: -webkit-box; 
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis; 
    line-height: 1.4; 
    max-height: calc(1.4em * 2);
    margin-bottom: 2px !important;
  }
  .blog-card-wrapper{
    margin-top: 10px !important;
  }

  .relevent-blog-card-wrapper h3, .relevent-blog-card-wrapper h3 a{
    display: -webkit-box; 
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
    text-overflow: ellipsis; 
    max-height: calc(1.4em * 2);
    margin-bottom: 2px !important;
  }


  .blog-txt-details p.dropcaps::first-letter {
    font-size: 3rem !important;
    font-weight: bold !important;
    float: left;
    margin-right: 5px;
    line-height: 1;
  }

  .share-btn{
    width: 150px !important;
}
  @media(min-width: 992px) and (max-width: 1400px){
    .share-btn{
        width: 150px !important;
    }

}